import React, { lazy } from 'react';
import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { AppRouteURL } from '../AppRouteURL';
import { Layout } from '../components/Layout/Layout';
import { LoadingScreen } from '../components/LoadingScreen/LoadingScreen';
import { IUser, RoleType } from '../models/User';
import UserAdminPage from '../pages/Admin/UserAdminPage/UserAdminPage';
import { AnalyzeResumePage } from '../pages/AnalyzeResumePage/AnalyzeResumePage';
import { CheckoutCanceledPage } from '../pages/CheckoutCanceledPage/CheckoutCanceledPage';
import { CheckoutSuccessPage } from '../pages/CheckoutSuccessPage/CheckoutSuccessPage';
import EditAccountPage from '../pages/EditAccountPage/EditAccountPage';
import { EditResumePage } from '../pages/EditResumePage/EditResumePage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import FeedbackPage from '../pages/FeedbackPage/FeedbackPage';
import Home from '../pages/Home/Home';
import { ImportProfilePage } from '../pages/ImportProfilePage/ImportProfilePage';
import { ListResumesPage } from '../pages/ListResumesPage/ListResumesPage';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import { ResumeWizardPage } from '../pages/ResumeWizardPage/ResumeWizardPage';

const AdminPage = lazy(
  () => import(/* webpackChunkName: "admin" */ '../pages/Admin/AdminPage'),
);

const ViewResumePage = lazy(
  () =>
    import(
      /* webpackChunkName: "view-resume" */ '../pages/ViewResumePage/ViewResumePage'
    ),
);

const getAuthenticatedRoutes = (loading: boolean, user?: IUser | null) =>
  createRoutesFromElements(
    <>
      <Route
        path={AppRouteURL.home}
        element={
          user ? (
            <Layout />
          ) : loading ? (
            <LoadingScreen />
          ) : (
            <Navigate to={AppRouteURL.login} replace />
          )
        }
        errorElement={<ErrorPage />}
      >
        <Route index element={<Home />} />
        <Route path={AppRouteURL.feedback} element={<FeedbackPage />} />

        {/* Account */}
        <Route
          path={`${AppRouteURL.profile}/:tab?`}
          element={<ProfilePage />}
        />
        <Route
          path={`${AppRouteURL.profileImport}`}
          element={<ImportProfilePage />}
        />

        <Route path={AppRouteURL.account} element={<EditAccountPage />} />

        {/* Resume */}
        <Route path={AppRouteURL.resumes.list} element={<ListResumesPage />} />
        <Route
          path={`${AppRouteURL.resumes.create}/:step?`}
          element={<ResumeWizardPage />}
        />
        <Route
          path={`${AppRouteURL.resumes.view}/:resumeId/:tab/:interviewId`}
          element={<ViewResumePage />}
        />
        <Route
          path={`${AppRouteURL.resumes.view}/:resumeId/:tab?`}
          element={<ViewResumePage />}
        />
        <Route
          path={`${AppRouteURL.resumes.edit}/:resumeId`}
          element={<EditResumePage />}
        />

        {/* Analyze */}
        <Route path={AppRouteURL.analyze} element={<AnalyzeResumePage />} />

        {/* Checkout */}
        <Route
          path={AppRouteURL.checkoutSuccess}
          element={<CheckoutSuccessPage />}
        />
        <Route
          path={AppRouteURL.checkoutCanceled}
          element={<CheckoutCanceledPage />}
        />

        {/* Admin */}
        <Route
          path={AppRouteURL.admin}
          element={user?.role !== RoleType.ADMIN && <Navigate to="/" replace />}
        >
          <Route index element={<AdminPage />} />
          <Route path={AppRouteURL.adminUser} element={<UserAdminPage />} />
        </Route>
      </Route>
    </>,
  );

export default getAuthenticatedRoutes;
